.userReviewRatingDropDown {
  display: flex;
  width: 176px;
  height: 48px;
  padding: 0px 10px 0px 20px;
  justify-content: space-between;
  align-items: center;
  border-radius: 60px;
  background: var(--base-color-green, #8beb84);

  color: var(--gray-800, #252d3c);

  /* Menu Text */
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 16px */

  cursor: pointer;
}

.link {
  color: var(--base-white, #fff);
  font-family: Lexend;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.link.selected {
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.ReviewRatingDropDownArrow {
  transition: all 0.4s ease-in-out;
  transform: rotate(180deg);
}

.selectedArrow {
  transform: rotate(0deg);
}

/* ----------------- */
.dropdownRatingMenu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.checkBox,
.checkBoxChecked {
  display: flex;
  width: 16px;
  height: 16px;
  padding: 2px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;

  border-radius: 3px;
  border: 1.67px solid var(--gray-50, #5f6d7e);
  transition: all 0.2s ease-in;
}

.checkBoxChecked {
  background: var(--base-color-green, #78ca72);
  border: 1.67px solid var(--gray-50, #78ca72);
}

.itemTitle {
  color: var(--gray-100, #49556d);

  /* Text S/Regular */
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.1px;

  /* Nav bar blur/Background blur nav bar */
  backdrop-filter: blur(25px);
}

.itemTitle:hover {
  color: #78ca72;
}

.filterInput {
  border: none;
  width: 100%;
  height: 48px;
}

.filterInput:focus {
  outline: none;
}

.error {
  color: red;
  margin: 10px;
}

@media only screen and (max-width: 1440px) {
  .dropdownRatingMenu {
    top: 12%;
    right: 2%;
  }
}

@media only screen and (max-width: 996px) {
  .dropdownRatingMenu {
    position: relative;
    margin-top: 10px;
    width: 100%;
    top: 0;
    right: 0;
  }
}

@media only screen and (max-width: 800px) {
  .userReviewRatingDropDown {
    width: 80vw;

    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
