.userReviewRatingDropDown {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  cursor: pointer;
}

.link {
  color: var(--base-white, #fff);
  font-family: Lexend;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.link.selected {
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.ReviewRatingDropDownArrow {
  transition: all 0.6s ease-in-out;
}

.selectedArrow {
  transform: rotate(180deg);
}

/* ----------------- */
.dropdownRatingMenu {
  width: 100%;
  border-top: 1px solid var(--gray-300, #2e3545);
  background: var(--gray-950, #0e1218);

  display: flex;
  flex-direction: column;
  align-items: center;

  position: absolute;
  top: 11%;
  left: 0;
}

.itemsContainer {
  display: flex;
  width: 1280px;
  padding: 32px;
  align-items: flex-start;
  gap: 32px;
}

.itemContainer {
  display: flex;
  padding: 10px;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  border-radius: 5px;
}

.detailsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
}

.itemTitle {
  color: var(--neutral-200, #f9f9f9);
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: -0.1px;
}

.itemTitle:hover {
  color: #78ca72;
}

.itemDescription {
  color: var(--neutral-800, #a5acba);
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.1px;
}

.ctaSection {
  display: flex;
  padding: 24px 112px;
  align-items: center;
  gap: 32px;
  align-self: stretch;
}

.contactUsBtn,
.infoBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 5px;

  color: var(--base-white, #fff);
  font-family: Lexend;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
}

@media only screen and (max-width: 1250px) {
  .itemsContainer {
    width: 100%;
  }
}

@media only screen and (max-width: 996px) {
  .dropdownRatingMenu {
    position: relative;
  }
}

@media only screen and (max-width: 910px) {
  .itemsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    grid-gap: 16px;
    width: 100%;
    justify-content: center;
  }
  .ctaSection {
    padding: 16px 32px;
  }
}

@media only screen and (max-width: 380px) {
  .userReviewRatingDropDown {
    width: 95vw;
    gap: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .dropdownRatingMenu {
    width: 95vw;
  }
}

@media only screen and (max-width: 325px) {
  .userReviewRatingDropDown {
    padding: 15px 20px;
  }
}
