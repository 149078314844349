.mainHeroContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #0e1218 0%, #2a374a 100%);
}

.heroContainer {
  display: flex;
  width: 1280px;
  padding: 48px 32px 80px 32px;
  align-items: center;
  gap: 64px;
  min-height: 528px;
}

.heroDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.heroTitle {
  color: var(--base-white, #fff);
  font-family: Lexend;
  font-size: 52px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 115.385% */
  letter-spacing: -0.52px;
}

.greenTxt {
  color: #8beb84;
}

.heroDescription {
  color: var(--primary-200, #b1ccfb);
  font-family: Lexend;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  /* 144.444% */
  letter-spacing: -0.1px;
  margin-top: 24px;
}

.heroButton {
  display: flex;
  padding: 12px 18px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #78ca72;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);

  color: var(--base-white, #fff);
  font-family: Lexend;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 146.667% */
  border: none;
  margin-top: 32px;
}

.heroImageContainer {
  display: flex;
  width: 528px;
  height: 528px;
  padding: 40.5px 41px 41px 40.5px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 10px;
}

.heroImageInsideContainer {
  display: flex;
  width: 446.5px;
  height: 446.5px;
  padding: 19.5px 20px 20px 19.5px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.HeroImageMobile {
  display: none;
}

.featuresContainer {
  display: flex;
  width: 100%;
  padding: 64px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 64px;
  margin-top: 32px;
  margin-bottom: 32px;

  background: #ffffff;
}

.featuresHeader {
  display: flex;
  width: 1280px;
  padding: 0px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.featuresTitle {
  color: var(--gray-700, #272d37);
  text-align: center;
  font-family: Lexend;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  /* 125% */
  letter-spacing: -0.64px;
}

.featuresSubtitle {
  color: var(--gray-50, #5f6d7e);
  text-align: center;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: -0.1px;
}

.features {
  display: flex;
  width: 1280px;
  padding: 0px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}

.featuresGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  grid-gap: 32px;
  width: 100%;
  justify-content: center;
}

.featuresRow {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 32px;
}

.featureBox {
  display: flex;
  padding: 24px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  border-radius: 5px;
  background: var(--base-white, #fff);

}

.featureTopRow {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.featureBtn {
  display: flex;
  padding: 12px 18px;
  justify-content: center;
  align-items: center;
  gap: 6px;

  border-radius: 6px;
  border: 1px solid var(--neutral-700, #dae0e6);
  background: var(--base-white, #fff);

  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);

  color: var(--gray-700, #272d37);

  font-family: Lexend;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 146.667% */
}

.featureDetailsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.featureDetailsTitle {
  color: var(--gray-700, #272d37);

  font-family: Lexend;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  /* 136.364% */
  letter-spacing: -0.22px;
}

.featureDetailsDescription {
  color: var(--gray-50, #5f6d7e);

  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: -0.1px;
}

.featuresRowLast {
  width: 100%;
}

.featuresFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.featuresFooterBtn {
  display: flex;
  padding: 12px 18px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 6px;
  background: #78ca72;

  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);

  color: var(--base-white, #fff);

  font-family: Lexend;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 146.667% */
  border: none;
}

.contentSectionContainer {
  display: flex;
  width: 100%;
  padding: 64px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 64px;
  background-color: #ffffff;
}

.contentSection1 {
  display: flex;
  width: 1280px;
  padding: 0px 32px;
  align-items: center;
  gap: 80px;
}

.photoImageTablet {
  display: none;
}

.photoImageMobile {
  display: none;
}

.contentSection1Details {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  flex: 1 0 0;
}

.contentSection1DetailsHeader {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 12px;
}

.contentSection1DetailsTitle {
  color: var(--gray-700, #272d37);

  font-family: Lexend;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 42px;
  /* 131.25% */
  letter-spacing: -0.32px;
}

.contentSection1DetailsDescription {
  color: var(--gray-50, #5f6d7e);

  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: -0.1px;
}

.contentSection1DetailsFeatures {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}

.contentSection1DetailsFeature {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.contentSection1DetailsFeatureDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.contentSection1DetailsFeatureTitle {
  color: var(--gray-700, #272d37);
  font-family: Lexend;
  font-size: 22px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  /* 136.364% */
  letter-spacing: -0.22px;
}

.contentSection1DetailsFeatureDescription {
  color: var(--gray-50, #5f6d7e);
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: -0.1px;
}

.contentSection1Footer {
  display: flex;
  width: 1280px;
  padding: 0px 32px;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.contentSection1FooterBtn1 {
  display: flex;
  width: 128px;
  padding: 12px 18px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid var(--neutral-700, #dae0e6);
  background: var(--base-white, #fff);

  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  color: var(--gray-700, #272d37);

  font-family: Lexend;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 146.667% */
}

.contentSection1FooterBtn2 {
  display: flex;
  /* width: 128px; */
  padding: 12px 18px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
  border-radius: 6px;
  background: #78ca72;

  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  color: var(--base-white, #fff);

  font-family: Lexend;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 146.667% */
  border: none;
}

.contentSection2 {
  display: flex;
  width: 1280px;
  padding: 0px 32px;
  align-items: center;
  gap: 124px;
}

.contentSection2Details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  flex: 1 0 0;
}

.contentSection2DetailsHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.contentSection2DetailsTitle {
  color: var(--gray-700, #272d37);

  font-family: Lexend;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 42px;
  /* 131.25% */
  letter-spacing: -0.32px;
}

.contentSection2DetailsPointsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.contentSection2DetailsPoint {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.contentSection2DetailsPointText {
  color: var(--gray-50, #5f6d7e);

  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: -0.1px;
}

.contentSection2DetailsBtn {
  display: flex;
  padding: 12px 18px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 6px;
  background: #78ca72;

  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
}

.contentSection2DetailsBtnText {
  color: var(--base-white, #fff);

  font-family: Lexend;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 146.667% */
}

.ofTheIndustry {
  position: relative;
  letter-spacing: -0.1px;
  line-height: 1.25rem;
  font-weight: 600;
}

.ofTheIndustry1 {
  position: relative;
  letter-spacing: -0.1px;
  line-height: 1.25rem;
  font-weight: 600;
  display: none;
}

.headline {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 4.25rem;
}

.subtitle {
  align-self: stretch;
  position: relative;
  font-size: 1rem;
  letter-spacing: -0.1px;
  line-height: 1.5rem;
  color: #5f6d7e;
}

.headlineSupportingText1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  font-size: 32px;
  color: #272d37;
}

.textContainer1 {

  display: flex;
  flex-direction: column;
  padding: 0rem 1rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  text-align: left;
}

.heroBase {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}

.container1 {
  width: 80rem;
  display: flex;
  flex-direction: column;
  padding: 5rem 1.88rem 5.75rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  z-index: 0;
}

.hero {
  position: relative;
  background-color: #f8f9fb;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0rem 0rem 0.13rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.88rem;
  color: #78ca72;
  font-family: Lexend;
}



/* MEDIA QUERY */
@media only screen and (max-width: 1280px) {

  .contentSection1,
  .contentSection2,
  .contentSection1Footer {
    width: 100%;
  }
}

@media only screen and (max-width: 1250px) {

  .featuresHeader,
  .features {
    width: 100%;
  }
}

@media only screen and (max-width: 1096px) {
  .contentSectionContainer {
    padding: 40px 32px;
    gap: 40px;
  }

  .contentSection1,
  .contentSection2 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 48px;
  }

  .photoImageDesktop {
    display: none;
  }

  .photoImageTablet {
    display: block;
    width: 100%;
  }
}

@media only screen and (max-width: 996px) {
  .heroContainer {
    display: flex;
    padding: 48px;
    flex-direction: column-reverse;
    align-items: center;
    gap: 40px;
    align-self: stretch;
  }

  .featuresContainer {
    display: flex;
    width: 100%;
    padding: 40px 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
  }

  .features {
    padding: 0px;
  }
}

@media only screen and (max-width: 600px) {
  .heroContainer {
    display: flex;
    padding: 32px 16px 40px 16px;
    align-items: center;
    gap: 32px;
    align-self: stretch;
  }

  .heroImageInsideContainer {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  .heroImageContainer {
    display: flex;
    width: 98%;
    height: 392px;
    padding: 0px;
    justify-content: center;
    align-items: center;
    border-radius: 7.424px;
  }

  .HeroImageDesktop {
    display: none;
  }

  .HeroImageMobile {
    display: block;
  }

  .heroTitle {
    color: var(--base-white, #fff);

    /* Headline xL/Mobile/Semibold */
    font-family: Lexend;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
    /* 122.222% */
    letter-spacing: -0.72px;
  }

  .heroWordVantar{
    font-size: 36px;
  }
  .heroWords{
    font-size: 36px;
    margin-top: 4px !important;
  }

  .heroDescription {
    margin-top: 16px;
  }

  .heroButton {
    width: 100%;
  }

  .featuresContainer {
    padding: 32px 16px;
  }

  .photoImageTablet {
    display: none;
  }

  .photoImageMobile {
    display: block;
    width: 100%;
    padding-left: 36px;
    padding-right: 36px;
  }

  .contentSectionContainer,
  .contentSection1,
  .contentSection2 {
    padding: 0px;
    gap: 0px;
  }

  .contentSection1Details,
  .contentSection2Details {
    display: flex;
    padding: 32px 16px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;
  }

  .contentSection2Details {
    align-items: flex-start;
  }

  .contentSection1DetailsHeader {
    gap: 8px;
  }

  .contentSection1DetailsTitle,
  .contentSection2DetailsTitle {
    font-size: 28px;
    line-height: 36px;
    /* 128.571% */
    letter-spacing: -0.56px;
  }

  .contentSection1DetailsFeatureTitle {
    font-size: 20px;
    line-height: 28px;
    /* 140% */
    letter-spacing: -0.4px;
  }

  .contentSection1Footer {
    margin-bottom: 32px;
  }
}

.blockHeader {
  width: 80rem;
  display: flex;
  flex-direction: column;
  padding: 0rem 2rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.imageIcon {
  flex: 1;
  position: relative;
  border-radius: 25px;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}

.galleryRow {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
}

.container2 {
  flex: 1;
  width: 80rem;
  display: flex;
  flex-direction: column;
  padding: 0rem 2rem;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
}

.gallery {
  position: relative;
  width: 50%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  text-align: center;
  font-size: 2rem;
  color: #272d37;
  font-family: Lexend;
  float: right;
}

.floatLeft {
  float: left;
  width: 50%;
  padding-right: 10px;
}

.subtitle2 {
  align-self: stretch;
  position: relative;
  font-size: 1rem;
  letter-spacing: -0.1px;
  line-height: 1.5rem;
  color: #5f6d7e;
  padding-bottom: 20px;
}

/* Mobile Styles */
.headlineSupportingText1 {
  font-size: 18px;
  /* Adjust font size for mobile */
}

/* Tablet Styles (768px and above) */
@media (min-width: 768px) {
  .container1 {
    width: 100%;
    /* Adjust container width for tablets */
    padding: 2rem 1.88rem;
    /* Adjust padding for tablets */
  }


}

@media (max-width: 768px) {
  .container1 {
    width: 100%;
    /* Adjust container width for tablets */
    padding: 2rem 1.88rem;
    /* Adjust padding for tablets */
  }

  .floatLeft {
    float: none;
    width: 100%;
    padding-right: 0px;
  }

  .gallery {
    min-width: 200px;
    height: auto;
    width: 100%;
  }

}

/* Desktop Styles (992px and above) */
@media (min-width: 992px) {
  .headlineSupportingText1 {
    font-size: 24px;
    /* Adjust font size for desktop */
  }

  .container1 {
    padding: 3rem 2rem;
    /* Adjust padding for desktop */
  }
}

@media (max-width: 600px) {
  .contentSection1DetailsFeature {
    flex-direction: column; /* Stack icon and text vertically */
  }

  .contentSection1Details {
    padding: 34px 52px
  }
}