.mainContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(180deg, #0e1218 0%, #2a374a 100%);
  /* border: 1px solid white; */
}
.loginContainer {
  display: flex;
  padding: 148px 112px;
  align-items: center;
  align-self: stretch;
}

.loginDetailsContainer {
  display: flex;
  width: 424px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  border-style: solid;
  color: rgb(120, 202, 114);
  padding-top: 20px;
  border-radius: 10px;
}
.authorizationContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  border-radius: 5px;
  width: 424px;
  height: 100%;
  background: var(--gray-800, #252d3c);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
}
.title {
  color: var(--neutral-200, #f9f9f9);
  text-align: center;
  font-family: Lexend;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px; /* 122.222% */
  letter-spacing: -0.72px;
}

.googleBtnContainer {
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  border-radius: 5px;
  border: 1px solid var(--gray-300, #2e3545);
  background: var(--gray-600, #2c3444);

  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);

  color: var(--neutral-200, #f9f9f9);

  font-family: Lexend;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
}

.emailBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;

  color: var(--neutral-200, #f9f9f9);

  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: -0.1px;
  cursor: pointer;
}

.divider {
  width: 108px;
  border-top: 2px solid #50596a;
}

.loginForm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.fieldContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.Login_form_field_text_message {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 20px;
}

.Login_form_field_error_meesage {
  color: rgb(255, 64, 64);
}

.label {
  color: var(--neutral-200, #f9f9f9);

  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.1px;
}

.input {
  display: flex;
  height: 46px;
  padding: 12px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid var(--gray-300, #2e3545);
  background: var(--gray-600, #2c3444);

  color: azure;
}

.input::placeholder {
  color: var(--neutral-800, #a5acba);

  font-family: Lexend;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 146.667% */
  letter-spacing: -0.1px;
}

.bottomContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.checkBoxContainer {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}

.checkBox {
  display: flex;
  width: 20px;
  height: 20px;
  padding: 2px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 5px;
  border: 1.67px solid var(--gray-300, #2e3545);
  background: var(--gray-800, #252d3c);
  accent-color: #2e3545;
}

.checkBoxLabel {
  color: var(--neutral-200, #f9f9f9);

  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: -0.1px;
}

.forgetPassword {
  color: #78ca72;

  font-family: Lexend;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
}

.footerBtnsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}
.loginBtn {
  display: flex;
  padding: 12px 18px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  border-radius: 6px;
  background: #78ca72;

  /* Shadow/XSM */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  color: var(--base-white, #fff);

  /* Text Button/Semibold Large */
  font-family: Lexend;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
  cursor: pointer;
}

.signUpSection {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.signupTxt {
  color: var(--neutral-800, #a5acba);

  /* Text L/Regular */
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.1px;
}

.registerBtn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #78ca72;

  /* Text Button/Semibold Large */
  font-family: Lexend;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
}
.imageContainer {
  /* background image cover no repeat */
  background-image: url("../../public/images/loginImage.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
}

/* MEDIA QUERY */
@media only screen and (max-width: 996px) {
  .imageContainer {
    display: none;
  }
  .loginContainer {
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

/* MEDIA QUERY */
@media only screen and (max-width: 649px) {
  .loginContainer {
    padding: 86px 16px;
  }
  .loginDetailsContainer {
    width: 100%;
    gap: 24px;
  }

  .title {
    font-size: 32px;
    line-height: 40px; /* 125% */
    letter-spacing: -0.64px;
  }
}
