.userReviewRatingDropDown {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  cursor: pointer;
}

.link {
  color: var(--base-white, #fff);
  font-family: Lexend;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.link.selected {
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.ReviewRatingDropDownArrow {
  transition: all 0.4s ease-in-out;
}

.selectedArrow {
  transform: rotate(20deg);
}

/* ----------------- */
.dropdownRatingMenu {
  position: absolute;

  right: 10%;  

  border-radius: 5px;
  border: 1px solid var(--neutral-700, #dae0e6);
  background: var(--base-white, #fff);

  /* Shadow/MD */
  box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.1);
}

.itemsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.itemTitle {
  color: var(--gray-100, #49556d);

  /* Text S/Regular */
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.1px;

  /* Nav bar blur/Background blur nav bar */
  backdrop-filter: blur(25px);
}

.itemTitle:hover {
  color: #78ca72;
}

@media only screen and (max-width: 1440px) {
  .dropdownRatingMenu {    
    right: 2%;
  }
}

@media only screen and (max-width: 996px) {
  .dropdownRatingMenu {
    position: relative;
    margin-top: 10px;
    width: 100%;
    top: 0;
    right: 0;
  }
}

@media only screen and (max-width: 800px) {
  .userReviewRatingDropDown {
    width: 80vw;

    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
