.mainContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--background-color-gray, #f5f5f5);
}

.contentContainer {
  /* width: 1280px; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: var(--background-color-gray, #f5f5f5);
  padding-top: 24px;
  padding-bottom: 50px;
  padding-left: 32px;
  padding-right: 32px;
  gap: 40px;
}

.pageContentContainer {
  display: flex;
  width: 984px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  /* width: 80%; */
}

.topNavRow {
  display: flex;
  /* width: 984px; */
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.navOptions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.filterDropDown {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 4px;
  background: #fff;

  /* Shadow/SM */
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px -1px rgba(16, 24, 40, 0.1);
}

.filterDropDownText {
  color: var(--gray-50, #5f6d7e);

  /* Body Text/Body 1 */
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 16px */
}

.navOptionContainer {
  display: flex;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.navOptionContainerActive {
  display: flex;
  padding: 8px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #2c3444;
}

.navOptionText {
  color: var(--gray-50, #5f6d7e);

  /* Body Text/Body 1 */
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 16px */
}

.projectsDivider {
  width: 100%;
  height: 1px;
  background: #dae0e6;
}

.projectsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(234px, 1fr));
  grid-gap: 24px 16px;
  width: 100%;
  justify-content: start;
}

.projectContainer {
  display: flex;
  /* width: 266px; */
  height: 269px;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  border-radius: 8px;
  background: #fff;

  /* Shadow/SM */
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px -1px rgba(16, 24, 40, 0.1);
  cursor: pointer;
}

.projectContainer:hover {
  background-color: #d3fad9; /* New background color on hover */
}

.imageContainer {
  /* width: 242px; */
  height: 135px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  border-radius: 8px;
  background: var(--neutral-300, #f7f7f8);
}

.projectDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  overflow-y: hidden;
}

.projectName {
  color: var(--gray-600, #2c3444);

  /* Headline/Headline 4 */
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 20px */
  letter-spacing: -0.2px;
}

.projectDescription {
  color: var(--gray-50, #5f6d7e);

  /* Body Text/Body 2..160 */
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
}

.notificationsContainer {
  display: inline-flex;
  padding: 16px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  /* width: 20%; */

  border-radius: 10px;
  background: #fff;

  /* Shadow/SM */
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px -1px rgba(16, 24, 40, 0.1);
}

.notificationsHeadingContainer {
  display: flex;
  padding: 0px 16px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.notificationsHeading {
  color: var(--gray-600, #2c3444);

  /* Headline/Headline 4 */
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 20px */
  letter-spacing: -0.2px;
}

.notificationsBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.notificationContainer {
  display: flex;
  padding: 12px 16px;
  align-items: flex-start;
  gap: 8px;
}

.notificationContent {
  display: flex;
  align-items: center;
  gap: 14px;
}

.notificationTextContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.notificationHeading {
  color: var(--gray-400, #333b48);

  /* Body Text/Body 2 */
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 14px */
}

.notificationText {
  color: var(--gray-50, #5f6d7e);
  font-family: Lexend;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 17.6px */

  width: 267px;
}

.notificationTime {
  color: var(--gray-25, #919ba7);
  font-family: Lexend;
  font-size: 11px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%; /* 11px */
}
