.authorizationContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  border-radius: 5px;
  width: 100%;
  height: 100vh;
  background: var(--gray-800, #252d3c);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
}
.title {
  color: var(--neutral-200, #f9f9f9);
  text-align: center;
  font-family: Lexend;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px; /* 122.222% */
  letter-spacing: -0.72px;
}
