/* ----------------------------------------- NAV BAR ----------------------------------------- */
.navBar {
  width: 100%;
  height: 86px;
  background-color: #0e1218;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--gray-300, #2e3545);
}

.navContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1216px;
  height: 100%;
  gap: 64px;
}

.linksContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.navLinks {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
}

.otherLinks {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.link,
.accesslink {
  color: var(--base-white, #fff);
  font-family: Lexend;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.accesslink {
  color: #78ca72;
}

.dropDownLink {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.loginBtn {
  display: flex;
  padding: 12px 18px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 6px;
  background: #78ca72;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);

  cursor: pointer;
}

.hamburger {
  display: none;
}

.loginBtn {
  position: relative; /* Set the position of the parent container to relative */
}

.dropdown {
  position: absolute;
  top: 100%; /* Position the dropdown just below the "Skrá inn" button */
  right: 0; /* Align the dropdown to the right edge of the "Skrá inn" button */
  z-index: 1; /* Ensure the dropdown appears above other elements */
  min-width: 120px;

  border-radius: 5px;
  border: 1px solid var(--neutral-700, #dae0e6);
  background: var(--base-white, #fff);

  /* Shadow/MD */
  box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.1);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 4px;
  padding-bottom: 4px;
  /* margin-top: 2px; */
}

.option {
  padding: 8px;
  cursor: pointer;
  border-radius: 5px;

  color: var(--gray-100, #49556d);

  /* Text S/Regular */
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.1px;

  /* Nav bar blur/Background blur nav bar */
  backdrop-filter: blur(25px);

  width: 100%;
}

.option:hover {
  background-color: #e9dede;
}

.logo {
  cursor: pointer;
}

/* MEDIA QUERY */
@media only screen and (max-width: 1245px) {
  .navContent {
    width: 97%;
  }
}

@media only screen and (max-width: 996px) {
  .navBar {
    width: 100%;
    height: 64px;
  }
  .navContent {
    width: 100%;
    padding: 16px;
  }
  /* .linksContainer {
    display: none;
  } */
  .hamburger {
    display: block;
    transition: all 0.6s ease-in-out;
  }

  .selectedHamburger {
    transform: rotate(90deg);
  }
}
