.mainContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--background-color-gray, #f5f5f5);
}

.contentContainer {
  /* width: 1280px; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: var(--background-color-gray, #f5f5f5);
}

.sidebarContainer {
  display: inline-flex;
  padding: 30px 20px 172px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-right: 1px solid var(--foundation-white-grey-2, #f5f5f7);
  background: #f8fff9;
}
.menuContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.filter {
  display: flex;
  width: 176px;
  height: 42px;
  padding: 0px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 60px;
  background: var(--base-color-green, #8beb84);

  color: var(--background-color-black, #0e1218);
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 16px */
}

.sidebarDivider {
  width: 216px;
  height: 1px;
  background: #252d3c;
}

.menuTitle {
  color: var(--gray-800, #252d3c);
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%; /* 16px */
}

.sizeOptionsContainer {
  display: flex;
  padding: 16px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  border-radius: 16px;
  background: var(--base-color-green-shade, #d3fad9);
}

.sizeDropDown {
  display: flex;
  width: 176px;
  height: 48px;
  padding: 0px 10px 0px 20px;
  justify-content: space-between;
  align-items: center;
  border-radius: 60px;
  background: var(--base-color-green, #8beb84);

  color: var(--gray-800, #252d3c);

  /* Menu Text */
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 16px */
}

.optionsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.option {
  display: flex;
  width: 176px;
  height: 48px;
  padding: 0px 10px 0px 20px;
  align-items: center;
  gap: 8px;
  color: var(--gray-50, #5f6d7e);
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 16px */
  border-radius: 60px;
  background: var(--white, #fff);
}

.checkBox,
.checkBoxChecked {
  display: flex;
  width: 16px;
  height: 16px;
  padding: 2px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;

  border-radius: 3px;
  border: 1.67px solid var(--gray-50, #5f6d7e);
}

.checkBoxChecked {
  background: var(--base-color-green, #78ca72);
  border: 1.67px solid var(--gray-50, #78ca72);
}

.pageContentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 34px 50px 34px;
  width: 100%;
  height: 100%;
}

.projectsContentContainer {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
}

.projectsTitle {
  color: var(--gray-600, #2c3444);

  /* Headline/Headline 1 */
  font-family: Lexend;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 32px */
  letter-spacing: -0.32px;
}

.projectsDivider {
  width: 100%;
  height: 1px;
  background: #dae0e6;
}

.titleRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.title {
  color: var(--gray-600, #2c3444);

  /* Headline/Headline 2 */
  font-family: Lexend;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 28px */
  letter-spacing: -0.28px;
}

.type,
.cost,
.skill,
.skillRequirements,
.documentRequirements,
.startDate,
.documents {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
}

.skillRequirements,
.documentRequirements,
.documents {
  flex-direction: column;
  gap: 10px;
}
.documents {
  gap: 40px;
  width: 100%;
}
.typeTitle,
.costTitle,
.skillTitle,
.skillRequirementsTitle,
.documentRequirementsTitle,
.startDateTitle,
.documentsTitle {
  color: var(--gray-800, #252d3c);

  /* Headline/Headline 4 */
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 20px */
  letter-spacing: -0.2px;
}

.createOfferBtnRow {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin-top: 64px;
}

.createOfferBtn {
  display: flex;
  width: 134px;
  height: 40px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: var(--gray-800, #252d3c);

  color: var(--white, #fff);

  /* Body Text/Body 1 */
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 16px */
  cursor: pointer;
}

.createOfferBtn:hover {
  background: var(--gray-800, #252d3c);
  color: var(--base-color-green, #8beb84);
}

.documentsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  grid-gap: 24px 16px;
  width: 100%;
  justify-content: start;
}

.documentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 16px;
}

.thumbnailContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 170px;
  height: 172px;
  border-radius: 6px;
  background: #f4edea;

  /* Shadow/SM */
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px -1px rgba(16, 24, 40, 0.1);

  flex-direction: column;
  cursor: pointer;
}
.thumbnailContainer:hover {
  background: #ece9e9;
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.2),
    0px 1px 2px -1px rgba(16, 24, 40, 0.2);
}

.documentIcon {
  width: 100%;
  height: 100%;
  border-radius: 6px;
}
.downloadRow {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-right: 10px;
  margin-top: 10px;
  width: 100%;
}
.downloadIcon {
  width: 40px;
}

.documentName {
  color: var(--gray-400, #333b48);
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 16px */
  letter-spacing: -0.16px;
}

.projectContainer {
  display: flex;
  /* width: 266px; */
  height: 269px;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  border-radius: 8px;
  background: #fff;

  /* Shadow/SM */
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px -1px rgba(16, 24, 40, 0.1);
}

.imageContainer {
  /* width: 242px; */
  height: 135px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  border-radius: 8px;
  background: var(--neutral-300, #f7f7f8);
}

.projectDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.projectName {
  color: var(--gray-600, #2c3444);

  /* Headline/Headline 4 */
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 20px */
  letter-spacing: -0.2px;
}

.projectDescription {
  color: var(--gray-50, #5f6d7e);

  /* Body Text/Body 2..160 */
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
}

.textEditorView span{
  background-color: #f5f5f5 !important;
  font-family: Lexend !important;
}
.textEditorView ul {
  padding-left: 15px;  
}

.textEditorView ol {
  padding-left: 20px;
}