/* ----------------------------------------- NAV BAR ----------------------------------------- */
.navBar {
  width: 100%;
  height: 86px;
  background-color: #0e1218;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--gray-300, #2e3545);
}

.navContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1216px;
  height: 100%;
  gap: 64px;
}

.linksContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.navLinks {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.otherLinks {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.linkContainer {
  display: flex;
  width: 110px;
  height: 40px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 6px;
  color: var(--white, #fff);

  /* Body Text/Body 1 */
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 16px */
}

.active {
  background: var(--gray-800, #252d3c);
}

.searchBarContainer {
  display: flex;
  /* width: 440px; */
  width: 45%;
  height: 44px;
  padding: 10px 14px;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;

  border-radius: 8px;
  border: 1px solid var(--neutral-600, #eaebf0);
  background: var(--base-white, #fff);

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.searchInput {
  width: 100%;
  border: none;
}
.searchInput:focus {
  outline: none;
}

.searchInput::placeholder {
  color: var(--gray-25, #919ba7);

  /* Text md/Regular */
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.link,
.accesslink {
  color: var(--base-white, #fff);
  font-family: Lexend;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.accesslink {
  color: #78ca72;
}

.dropDownLink {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.loginBtn {
  display: flex;
  padding: 12px 18px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 6px;
  background: #78ca72;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
}

.logo {
  cursor: pointer;
}

.hamburger {
  display: none;
}

.skraBiddButton {
  display: flex;
  height: 40px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  border-radius: 6px;
  background-color: #78ca72 !important;

  /* Body Text/Body 1 */
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 16px */
}

.skraBiddButton:hover{
  background-color: #d3fad9 !important;
}


/* MEDIA QUERY */
@media only screen and (max-width: 1245px) {
  .navContent {
    width: 97%;
  }
}

@media only screen and (max-width: 996px) {
  .navBar {
    width: 100%;
    height: 64px;
  }
  .navContent {
    width: 100%;
    padding: 16px;
  }
  .linksContainer {
    display: none;
  }
  .hamburger {
    display: block;
    transition: all 0.6s ease-in-out;
  }

  .selectedHamburger {
    transform: rotate(90deg);
  }
}
