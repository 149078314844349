/* The Modal (background) */
.modal {
  visibility: hidden;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  right: 0;
  top: 10%;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow-y: auto;
  overflow-x: hidden;
  /* Enable scroll if needed */
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
  -webkit-animation-name: fadeIn;
  /* Fade in the background */
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s;
  transition: all 0.6s ease-in-out;

  display: none;
  /* display: flex; */
  align-items: flex-start;
  justify-content: flex-end;
}

/* width */
.modal::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.modal::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.modal::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 100px;
}

/* Handle on hover */
.modal::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.modal.active {
  visibility: visible;
}

/* Modal Content */
.modal_content {
  position: absolute;
  right: 0px;
  padding-bottom: 40px;
  -webkit-animation-name: slideIn;
  -webkit-animation-duration: 0.6s;
  animation-name: slideIn;
  animation-duration: 0.6s;

  width: 100%;
  /* height: 300px; */
  border-bottom: 1px solid var(--gray-300, #2e3545);

  background: var(--gray-600, #2c3444);
  /* background: var(--gray-950, #ffffff); */

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  overflow: auto;
  overflow-x: hidden;
}

.modal_content.inactive {
  animation-name: slideOut;
}

/* The Close Button */
.closeBtn {
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;

  display: flex;
  height: 40px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;

  border-radius: 5px;
  border: 1px solid var(--gray-300, #ffffff);
  background: var(--gray-400, #333b48);
}

.closeBtn:hover {
  outline: 3px solid #003d84;
}

.linksContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 70px;
  gap: 40px;
}

.navLinks {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  flex-direction: column;
  width: 100%;
}

.linkContainer {
  display: flex;
  width: 110px;
  height: 40px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 6px;
  color: var(--white, #fff);

  /* Body Text/Body 1 */
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 16px */
}

.active {
  background: var(--gray-800, #252d3c);
}

.searchBarContainer {
  display: flex;
  /* width: 440px; */
  width: 45%;
  height: 44px;
  padding: 10px 14px;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;

  border-radius: 8px;
  border: 1px solid var(--neutral-600, #eaebf0);
  background: var(--base-white, #fff);

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.searchInput {
  width: 100%;
  border: none;
}
.searchInput:focus {
  outline: none;
}

.searchInput::placeholder {
  color: var(--gray-25, #919ba7);

  /* Text md/Regular */
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.otherLinks {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-bottom: 10%;
  width: 100%;
}

.link,
.accesslink {
  color: var(--base-white, #fff);
  font-family: Lexend;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.accesslink {
  color: #78ca72;
}

.loginBtn {
  display: flex;
  padding: 12px 18px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 6px;
  background: #78ca72;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
}
/* Add Animation */
@-webkit-keyframes slideIn {
  from {
    right: -300px;
    opacity: 0;
  }

  to {
    right: 0;
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    right: -300px;
    opacity: 0;
  }

  to {
    right: 0;
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    right: 0px;
    opacity: 1;
  }

  to {
    right: -300px;
    opacity: 0;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media only screen and (max-width: 996px) {
  .modal {
    display: flex;
  }
}

@media only screen and (max-width: 800px) {
  .searchBarContainer {
    width: 80%;
  }
}

@media only screen and (max-width: 530px) {
  .modal_content {
    width: 85vw;
  }

  .closeBtn {
    width: 40px;
    height: 40px;
    top: 8px;
    left: 8px;
    box-shadow: 0px 3.2172px 3.2172px rgba(0, 0, 0, 0.25);
  }

  .closeIcon {
    width: 16px;
    height: 16px;
  }

  .closeBtn:hover {
    outline: 2px solid #003d84;
  }
}

@media only screen and (max-width: 300px) {
  .modal_content {
    width: 95%;
  }
}
