#Dokobit-identity-container {
  width: 100%;
}
#dokobit-identity {
  position: relative;
  max-width: 470px;
  margin: 0 auto;
  padding: 8px;
  font-family: Lexend, Roboto, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#dokobit-identity .dokobit-main-logo {
  text-align: center;
}

#dokobit-identity .d-none {
  display: none;
}
#dokobit-identity .dokobit-main {
  width: 100%;
}

#dokobit-identity .d-block {
  display: block;
}

#dokobit-identity .dokobit-main--method {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  font-weight: 500;
  margin: 24px 0 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  background: #fff;
  -webkit-transition: 0;
  transition: 0;
  text-align: center;
  padding: 0 0 24px;
}

#dokobit-identity .dokobit-main--method {
  background-color: #0000 !important;
  border: none !important;
}

#dokobit-identity .dokobit-main__back {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 24px;
  padding: 4px 24px 4px 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 40px;
}

#dokobit-identity .dokobit-main form {
  padding: 10px 24px 0;
  border: 0;
}

#dokobit-identity .dokobit-main form .d-form-group {
  position: relative;
  margin-bottom: 32px;
}

#dokobit-identity .dokobit-main form .d-form-group .d-input-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 9px;
}

#dokobit-identity .dokobit-main form .d-form-group .d-input-group {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  height: 56px;
  font-size: 16px;
  padding: 0 0 0 16px;
  border: 1px solid rgba(0, 0, 0, 0.26);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 4px;
  background: #78ca72;
}

#dokobit-identity
  .dokobit-main
  form
  .d-form-group
  .d-input-group
  .select-document {
  left: 0;
}

#dokobit-identity .select-document {
  position: absolute;
  list-style: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  width: 100%;
  min-width: 180px;
  color: rgba(0, 0, 0, 0.87);
  background: #fff;
  -webkit-box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  z-index: 5;
  top: -30px;
  border-radius: 4px;
  visibility: hidden;
  opacity: 0;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
  -webkit-transform: translateY(-1em);
  -ms-transform: translateY(-1em);
  transform: translateY(-1em);
  z-index: -1;
  -webkit-transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 0.01s;
  -webkit-transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s;
  -o-transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s;
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s;
}

#dokobit-identity .dokobit-main form .d-form-group .selected-document.disabled {
  cursor: default;
}

#dokobit-identity
  .dokobit-main
  form
  .d-form-group
  .d-input-group
  .selected-document {
  width: auto;
  min-width: 32px;
  height: 34px;
  padding: 11px 7px;
  border: 0 !important;
}

#dokobit-identity .dokobit-main form .d-form-group .selected-document {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  height: 56px;
  font-size: 16px;
  padding: 0 0 0 16px;
  border: 1px solid rgba(0, 0, 0, 0.26);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 4px;
  background: #fff;
}

#dokobit-identity .dokobit-main form .d-form-group .selected-document,
#dokobit-identity .d-methods-list {
  background: var(--gray-600, #2c3444) !important;
}

#dokobit-identity .selected-document {
  position: relative;
  cursor: pointer;
  padding-left: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

#dokobit-identity .dokobit-main form .d-form-group .d-input-group input {
  border: none !important;
  height: 54px;
  margin-left: 8px;
}
#dokobit-identity .dokobit-main form .d-form-group input:valid {
  background: rgba(0, 0, 0, 0);
}

#dokobit-identity .dokobit-main form .d-form-group input {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  height: 56px;
  font-size: 16px;
  font-weight: 400;
  padding: 0 0 0 16px;
  border: 1px solid rgba(0, 0, 0, 0.26);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 4px;
  background: #fff;
}
#dokobit-identity .dokobit-main form .d-form-group input {
  align-items: center;
  align-self: stretch;
  color: azure !important;
  display: flex;
  height: 46px;
  background: var(--gray-600, #2c3444) !important;
  border: 1px solid var(--gray-300, #2e3545);
  border-radius: 6px;
  gap: 8px;
  padding: 12px 16px;
}
#dokobit-identity .dokobit-main form .d-form-group input:valid ~ label,
#dokobit-identity .dokobit-main form .d-form-group label.select-label {
  left: 9px;
  top: -9px;
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
  background: #fff;
  padding: 0 6px;
  color: #548D4F;
  color: rgba(0, 0, 0, 0.6);
}
#dokobit-identity .dokobit-main form .d-form-group input:valid ~ label,
#dokobit-identity .dokobit-main form .d-form-group input:focus ~ label,
#dokobit-identity .dokobit-main form .d-form-group label.select-label {
  left: 0px !important;
  top: -18px !important;
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
  background: var(--gray-600, #2c3444) !important;
  padding: 4px 12px !important;
  color: #ffffff !important;
  color: rgb(255 255 255) !important;
  border-radius: 5px;
}

#dokobit-identity .dokobit-main form .d-form-group label {
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  font-weight: 400;
  position: absolute;
  pointer-events: none;
  line-height: 28px;
  letter-spacing: 0.15px;
  left: 16px;
  top: 15px;
  -o-transition: 0.2s ease all;
  transition: all 0.2s ease;
  -moz-transition: 0.2s ease all;
  -webkit-transition: all 0.2s ease;
}

#mobile > form > div.dokobit-main-links > button,
#audkenni_app > form > div.dokobit-main-links > button {
  /* visibility: hidden; */
  align-items: center;
  align-self: stretch;
  box-shadow: rgba(16, 24, 40, 0.04) 0px 1px 2px 0px;
  color: var(--base-white, #fff);
  cursor: pointer;
  display: flex;
  font-family: Lexend;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  justify-content: center;
  line-height: 22px;
  background: rgb(120, 202, 114);
  border-radius: 6px;
  gap: 6px;
  padding: 12px 18px;
}
#dokobit-identity .dokobit-main form button {
  display: block;
  text-decoration: none;
  text-align: center;
  background: #548D4F;
  color: #fff;
  min-height: 50px;
  line-height: 50px;
  border-radius: 100px;
  font-size: 15px;
  font-weight: 500;
  line-height: 16px;
  cursor: pointer;
  padding: 0 15px;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  z-index: 20;
  border: none;
}
#dokobit-identity .d-none {
  display: none;
}
#dokobit-identity .dokobit-main--method {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  font-weight: 500;
  margin: 24px 0 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  background: #fff;
  -webkit-transition: 0;
  transition: 0;
  text-align: center;
  padding: 0 0 24px;
}
#dokobit-identity .dokobit-main--method {
  background-color: #0000 !important;
  border: none !important;
}

#dokobit-identity .dokobit-main--method {
  background-color: #0000 !important;
  border: none !important;
}

#dokobit-identity .dokobit-main__back {
  display: none !important;
}
#mobile > form > div.dokobit-main-links > button,
#audkenni_app > form > div.dokobit-main-links > button {
  /* visibility: hidden; */
  align-items: center;
  align-self: stretch;
  box-shadow: rgba(16, 24, 40, 0.04) 0px 1px 2px 0px;
  color: var(--base-white, #fff);
  cursor: pointer;
  display: flex;
  font-family: Lexend;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  justify-content: center;
  line-height: 22px;
  background: rgb(120, 202, 114);
  border-radius: 6px;
  gap: 6px;
  padding: 12px 18px;
}

#dokobit-identity .dokobit-main form .d-form-group input {
  align-items: center;
  align-self: stretch;
  color: azure !important;
  display: flex;
  height: 46px;
  background: var(--gray-600, #2c3444) !important;
  border: 1px solid var(--gray-300, #2e3545);
  border-radius: 6px;
  gap: 8px;
  padding: 12px 16px;
}

#dokobit-identity .dokobit-main form .d-form-group .d-input-group {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  height: 56px;
  font-size: 16px;
  padding: 0 0 0 16px;
  border: 1px solid rgba(0, 0, 0, 0.26);
  color: #78ca72 !important;
  border-radius: 4px;
  background: var(--gray-600, #2c3444) !important;
}

#dokobit-identity .dokobit-main form .d-form-group .d-input-group.focus,
#codeAudkenniApp.focus {
  border-color: #78ca72 !important;
  box-shadow: 0 0 0 1px #78ca72 !important;
}

#dokobit-identity .dokobit-main form .d-form-group .d-input-group input:focus {
  border-color: #78ca72 !important;
}

#dokobit-identity .dokobit-main form .d-form-group .selected-document,
#dokobit-identity .d-methods-list {
  background: var(--gray-600, #2c3444) !important;
}

#dokobit-identity .d-methods-list__method:hover {
  background: var(--gray-600, #000000) !important;
  color: #fff !important;
}

#dokobit-identity .dokobit-main form .d-form-group input:valid ~ label,
#dokobit-identity .dokobit-main form .d-form-group input:focus ~ label,
#dokobit-identity .dokobit-main form .d-form-group label.select-label {
  left: 0px !important;
  top: -18px !important;
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
  background: var(--gray-600, #2c3444) !important;
  padding: 4px 12px !important;
  color: #ffffff !important;
  color: rgb(255 255 255) !important;
  border-radius: 5px;
}

#dokobit-identity .dokobit-main form .d-form-group label {
  color: rgba(255, 255, 255, 0.6) !important;
}

#dokobit-identity .d-control-code__title,
#dokobit-identity .d-control-code__code,
#dokobit-identity .d-control-code__time,
#dokobit-identity .d-control-code-description,
#dokobit-identity .d-methods-list__method label {
  color: #ffffff !important;
}

.dokobit-main-links__item.is-reversed.dokobit-main-btn--bubble-fx:hover {
  background-color: #548D4F !important;
} 

