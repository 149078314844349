.footerContainer {
  width: 100%;
  padding: 64px 0px;
  background: var(--gray-950, #0e1218);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
}

.content {
  display: flex;
  justify-content: space-between;
  width: 1216px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.colsContainer {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  width: 100%;
  gap: 150px;
}

.logoCol {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;  
}

.slogan {
  color: var(--neutral-800, #a5acba);
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.1px;
}

.resourcesColDesktop,
.resourcesColTablet,
.emailCol {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}
.resourcesColTablet {
  width: 50%;
}

.colsContainerTablet,
.emailColMobile {
  display: none;
}

.heading {
  color: var(--neutral-200, #f9f9f9);
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: -0.1px;
}

.linksContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.link {
  color: var(--neutral-800, #a5acba);
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: -0.1px;
}

.emailContainer {
  display: flex;
  align-items: center;

  width: 100%;
}

.emailInput {
  display: flex;
  height: 46px;
  padding: 12px 16px;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;

  border-radius: 6px 0px 0px 6px;
  border: 1px solid var(--gray-300, #2e3545);
  background: var(--gray-600, #2c3444);
}

.emailInput::placeholder {
  color: var(--neutral-800, #a5acba);
  font-family: Lexend;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 146.667% */
  letter-spacing: -0.1px;
}

.emailSubmitButton {
  display: flex;
  padding: 12px 18px;
  justify-content: center;
  align-items: center;
  gap: 6px;

  border-radius: 0px 6px 6px 0px;
  background: #78ca72;

  color: var(--base-white, #fff);
  font-family: Lexend;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
}

.divider {
  margin-top: 48px;
  margin-bottom: 24px;
  border-top: 1px solid var(--gray-300, #2e3545);
  width: 100%;
}

.bottomBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.copyRightText {
  color: var(--neutral-800, #a5acba);
  text-align: center;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.1px;
}

.iconsContainer {
  display: flex;
  gap: 24px;
}

/* MEDIA QUERY */
@media only screen and (max-width: 1245px) {
  .footerContainer {
    padding: 5.23% 4.17%;
  }
  .resourcesColDesktop {
    display: none;
  }

  .colsContainer {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    width: 100%;
    gap: 6.82%;
  }

  .colsContainerTablet {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    width: 100%;
    gap: 6.82%;
    margin-top: 32px;
  }
}

/* MEDIA QUERY */
@media only screen and (max-width: 600px) {
  .emailCol {
    display: none;
  }
  .colsContainerTablet {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .resourcesColTablet {
    gap: 16px;
  }
  .emailColMobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
  }

  .divider {
    margin-top: 24px;
    margin-bottom: 16px;
  }
}

/* MEDIA QUERY */
@media only screen and (max-width: 496px) {
  .bottomBar {
    /* justify-content: flex-start; */
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;
  }
  .iconsContainer {
    justify-content: center;
    align-items: center;
    gap: 64px;
  }
}
